<template>
  <v-row justify="center">
    <v-col cols="12" md="10">
      <v-skeleton-loader v-if="loading"
        type="card-heading, heading, sentence, heading, chip, heading, paragraph"
      >
      </v-skeleton-loader>
      <template v-else>
        <v-card v-if="foundRecipe" shaped>
        <v-toolbar flat dark color="teal">
          <v-toolbar-title>{{editedRecipe.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon v-if="editable" @click="isEditing = !isEditing">
            <v-icon>{{!isEditing ? `mdi-pencil` : `mdi-pencil-off`}}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-alert v-if="hasUnsavedEdits"
          class="my-2 px-4"
          dense
          border="left"
          type="info"
        >There are unsaved changes.</v-alert>

        <v-hover>
          <template v-slot:default="{ hover }">
            <v-img 
              max-height="350" contain
              :src="imageUrl"
            >
              <v-fade-transition>
                <v-overlay
                  v-if="hover && isEditing"
                  absolute
                  color="info"
                >
                  <v-btn 
                    :loading="selectingImage"
                    color="primary" 
                    class="text-none" 
                    rounded 
                    depressed
                    @click="onUploadImageClick"
                  >
                    <v-icon left>mdi-cloud-upload</v-icon>Upload
                  </v-btn>
                  <input 
                    ref="uploader" 
                    class="d-none" 
                    type="file" 
                    accept="image/*" 
                    @change="onFileChanged"
                  >
                </v-overlay>
              </v-fade-transition>
            </v-img>
          </template>
        </v-hover>

        <v-card-text>
          <!-- Recipe Name -->
          <v-row dense>
            <v-col cols="12">
              <v-subheader>Name</v-subheader>
            </v-col>
            <v-col cols="12" class="px-4">
              <v-text-field dense
                :readonly="!isEditing"
                v-model="editedRecipe.name"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Ingredients -->
          <v-row dense justify="center">
            <v-col cols="12">
              <v-subheader>Ingredients</v-subheader>
              <v-chip v-for="(ingredient, index) in editedRecipe.ingredients" dark
                @click="isEditing ? editIngredient(index) : ''"
                :color="getIngredientColor(ingredient)"
                @click:close="removeIngredient(index)"
                :close="isEditing" close-icon="mdi-delete"
                class="ma-2"
                :key="index"
              >
                <v-avatar color="white" left>
                  <span>{{ingredient.type.icon != null ? ingredient.type.icon : '🧊'}}</span>
                </v-avatar>
                {{ingredient.qty}}
                {{ingredient.customMeasurement != null ? ingredient.customMeasurement : ingredient.measurement}}
                {{ingredient.type.name || ingredient.type}}
              </v-chip>
              <br/>
            </v-col>

            <!-- Add New Ingredient -->
            <template v-if="addNewIngredient && isEditing">
              <v-col cols="12" md="8">
                <v-sheet dark color="indigo" rounded="xl" width="100%">
                  <v-subheader>New Ingredient</v-subheader>
                  <v-col cols="12">
                    <qty-measurement-picker
                      :qty.sync="newIngredientQty"
                      :measurement.sync="newIngredientMsrmnt"
                    ></qty-measurement-picker>
                  </v-col>
                  <v-col cols="12">
                    <ingredient-picker :ingredient.sync="newIngredient"></ingredient-picker>
                  </v-col>
                  <v-col cols="12">
                    <v-row justify="end">
                      <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <v-btn class="mx-2" rounded color="error" @click="addNewIngredient = false">Cancel</v-btn>
                        <v-btn rounded color="success" :disabled="!canSaveNewIngredient" @click="saveNewIngredient()">Save</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>
            </template>
            <v-col cols="12">
              <v-btn small rounded v-if="isEditing" class="ma-2" color="success" :disabled="addNewIngredient" @click="addNewIngredient = true">
                Add New Ingredient
              </v-btn>
            </v-col>
          </v-row>

          <!-- Edit Ingredient -->
          <template v-if="isEditing && editingIngredient">
            <v-col cols="12" md="8">
              <v-sheet dark color="blue-grey lighten-2" rounded="xl" width="100%">
                <v-col cols="12">
                  <qty-measurement-picker
                    :qty.sync="editedIngredient.qty"
                    :measurement.sync="editedIngredient.measurement"
                  ></qty-measurement-picker>
                </v-col>
                <v-col cols="12">
                  <ingredient-picker
                    :ingredient.sync="editedIngredient"
                  ></ingredient-picker>
                </v-col>
                <v-col cols="12">
                  <v-row justify="end">
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                      <v-btn class="mx-2" rounded color="error" @click="editingIngredient = false">Cancel</v-btn>
                      <v-btn rounded color="success" @click="saveEditingIngredient">Save</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-sheet>
            </v-col>
          </template>

          <!-- Instructions -->
          <v-row>
            <v-col cols="12">
              <v-subheader>Instructions</v-subheader>
              <v-card :flat="!isEditing">
                <v-list>

                  <template v-for="(step, index) in editedRecipe.steps">
                    <v-list-item dense :key="index">
                      <template v-slot:default="{}">
                        <v-list-item-content>
                          <v-text-field dense :prefix="`${index+1}. `" :readonly="!isEditing" :value="step" @change="updateStep($event, index)"></v-text-field>
                        </v-list-item-content>

                        <v-list-item-action v-if="isEditing">
                          <v-speed-dial small
                            right
                            direction="left"
                            transition="slide-x-reverse-transition"
                          >
                            <template v-slot:activator>
                              <v-btn small icon fab>
                                <v-icon>
                                  mdi-pencil
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-btn fab dark small color="green">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn fab dark small color="red" @click="deleteStep(index)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-speed-dial>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>

                  <template>
                    <v-divider></v-divider>
                    <v-list-item v-if="addNewStep && isEditing">
                      <v-list-item-content>
                        <v-form v-on:submit.prevent="saveNewStep">
                          <v-text-field dense :prefix="`${editedRecipe.steps.length+1}. `"
                            full-width append-icon="mdi-content-save"
                            v-model="newStepText"
                          >
                          </v-text-field>
                        </v-form>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-divider></v-divider>
                  <v-list-item dense v-if="isEditing">
                    <v-btn small rounded class="ma-2" color="success" @click="addNewStep = true">Add New Step</v-btn>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-subheader class="mt-4">Private</v-subheader>
              <v-switch @change="updateRecipePublicity" :readonly="!isEditing" v-model="editedRecipe.private" :label="`${editedRecipe.private ? 'Yes' : 'No'}`"></v-switch>
              <!-- <v-checkbox v-model="editedRecipe.public" :label="`Public: ${editedRecipe.public ? 'Yes' : 'No'}`"></v-checkbox>
              <v-checkbox v-model="editedRecipe.private" :label="`Private: ${editedRecipe.private ? 'Yes' : 'No'}`"></v-checkbox> -->
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-subheader class="mt-4">Category</v-subheader>
            <v-col cols="12">
              <v-combobox multiple chips
                v-model="editedRecipe.categories"
                label="category"
                hint="How should this be categorized as?"
              ></v-combobox>
            </v-col>
          </v-row> -->
        </v-card-text>

        <!-- Delete confirmation -->
        <v-card-actions>
          <template v-if="isEditing">
            <v-dialog v-model="deleteConfirmation" max-width="350"
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0" rounded color="error"
                  v-bind="attrs"
                  v-on="on"
                >
                  Delete
                  <v-icon right>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-toolbar dense flat color="red" dark>
                  <v-spacer></v-spacer>
                  ⚠️ Delete {{editedRecipe.name}} ⚠️
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="my-2">
                  <p>
                  WARNING ⚠️: You will be deleting {{editedRecipe.name}}. This is PERMANENT and is NOT UNDOABLE.
                  If you would you to continue, click "Delete" below.
                  </p>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn elevation="0" rounded color="error" @click="deleteRecipe"
                  >
                    Delete
                    <v-icon right>mdi-delete-forever</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <v-spacer></v-spacer>
          <v-btn dark rounded color="indigo" v-if="canAddToStash" @click="addToStash">
            Add to Stash
            <v-icon right>mdi-wallet-plus</v-icon>
          </v-btn>
          <v-btn v-if="isEditing" rounded color="error" @click="cancelEdit()">
            Cancel
          </v-btn>
          <v-btn v-if="isEditing" rounded :disabled="changes.length == 0" color="success" @click="saveEdit">
            Save
            <v-icon right>mdi-content-save-edit</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>

      <p v-else class="text-h2 text-center my-7">Sorry, nothing found 😥</p>
      </template>
    </v-col>
  </v-row>
</template>
<script>
  import { Storage } from '../firebase/storage.js';
  import { DB } from '../firebase/db.js'
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'Recipe',
    data: () => ({
      loading: true,
      foundRecipe: false,

      originalRoutedRecipe: {},
      editedRecipe: {},
      editedRecipeImage: null,
      editedRecipeImageUrl: '',

      isEditing: false,
      editingIngredient: false,
      editedIngredient: {},

      addNewIngredient: false,
      newIngredient: {},
      newIngredientQty: null,
      newIngredientMsrmnt: 'mL',

      addNewStep: false,
      newStepText: null,

      changes: [],
      deleteConfirmation: false, 

      selectingImage: false,
    }),
    mounted(){
      let recipeId = this.$route.params.id
      let foundRecipe = this.storeRecipes.filter( item => item.id == recipeId)
      // console.log(foundRecipe)

      if(foundRecipe.length == 0){

        DB.collection('recipes')
          .doc(recipeId)
          .get()
          .then( document => {
            if(document.exists){

              this.originalRoutedRecipe = {id: document.id, ...document.data()}
              this.editedRecipe = {id: document.id, ...document.data()}

              this.foundRecipe = true;

            } else {
              console.error('error')
            }

            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            console.log(error)
            this.foundRecipe = false;
            // console.log(error)
          })
      } else {

        this.foundRecipe = true;
        var fndRecipe = foundRecipe[0];

        this.originalRoutedRecipe = {id: fndRecipe.id, ...fndRecipe};
        this.editedRecipe = {...this.originalRoutedRecipe};



        this.loading = false;
      }
    },
    computed: {
      ...mapState({
        storeRecipes: 'recipes',
      }),
      ...mapGetters({
        user: 'user'
      }),
      canAddToStash(){
        return !(this.user.data && (this.originalRoutedRecipe.ownerId == this.user.data.id));
      },
      editable(){
        return this.user.data && (this.originalRoutedRecipe.ownerId == this.user.data.id);
      },
      canSaveNewIngredient(){
        if(this.newIngredientQty == null || this.newIngredientQty == '') {
          return false;
        }

        if(Object.keys(this.newIngredient).length === 0){
          return false;
        }

        return true;
      },
      canSaveEditingIngredient(){
        if(this.editedIngredient == null || this.editedIngredient.qty == ''){
          return false;
        }

        return true;
      },
      hasUnsavedEdits(){
        if(this.changes.length > 0){
          return true;
        } else if(this.editedRecipe.name != this.originalRoutedRecipe.name){
          return true;
        } else {
          return false;
        }
      },
      imageUrl(){
        return this.editedIngredient.thumbnail === '' || 
                this.editedIngredient.thumbnail === undefined || 
                this.editedIngredient.thumbnail === null  
                  ? this.editedRecipeImageUrl !== '' ? this.editedRecipeImageUrl :'../img/placeholder-image.png' 
                  : this.editedIngredient.thumbnail;
      }
    },
    methods: {
      onUploadImageClick(){
        window.addEventListener('focus', () => {
          this.isSelecting = false;
        }, { once: true })

        this.$refs.uploader.click()
      },
      onFileChanged(e){
        this.addChange('update','thumbnail',{
          original: this.imageUrl,
          new: URL.createObjectURL(e.target.files[0])
        });

        this.editedRecipeImage = e.target.files[0];
        this.editedRecipeImageUrl = URL.createObjectURL(e.target.files[0]);
      },
      editIngredient(index) {
        var ingredientToEdit = this.editedRecipe.ingredients.find( (item, ind) => ind === index);
        console.log(ingredientToEdit)
        this.editingIngredient = true;

        this.editedIngredient = {index: index, ...ingredientToEdit};
      },
      addToStash(){
        // Add recipes to list of stores
        var recipeToAdd = this.originalRoutedRecipe;
        delete recipeToAdd.id;
        recipeToAdd.ownerId = this.user.data.id;

        console.log(recipeToAdd)
        this.$store.dispatch('addRecipe', recipeToAdd);
        this.$emit('showAlert', {text: 'Success! Enjoy your recipe in your stash!', type: 'success'})

        setTimeout(() => {
          this.$router.push(`/recipes`)
        }, 2000);
      },
      deleteRecipe(){
        this.$store.dispatch('deleteRecipe', {id: this.$route.params.id})
        this.$emit('showAlert', {text: 'This recipe has been deleted!', type: 'error', top: true});

        setTimeout(() => {
          this.$router.push('/recipes');
        }, 1500
        );
      },
      updateStep(step, id){
        var originalStep = this.editedRecipe.steps.find( (item, index) => index == id );
        this.addChange('update', 'step', {orignal: originalStep, new: step, index: id} )
        this.editedRecipe.steps.splice(id,1,step);
        console.log(`${id}: ${step}`)
      },
      saveNewStep(){
        if(this.newStepText.length <= 2 ){
          this.$emit('showAlert', {text: 'Your step is a bittt short.', type: 'warning', top: true});
          return false;
        }
        this.editedRecipe.steps.push(this.newStepText)
        this.addChange('add', 'step', {step: this.newStepText, index: this.editedRecipe.steps.length-1 });
        // this.changes.push({action: 'add', type: 'step', data: this.newStepText});

        this.newStepText = ''
        this.addNewStep = false
      },
      deleteStep(index){
        var stepToSplice = this.editedRecipe.steps.find( (item, ind) => ind === index )

        this.editedRecipe.steps.splice(index, 1)
        this.addChange('remove', 'step', {step: stepToSplice, index: index});
        // this.changes.push({action: 'remove', type: 'step', data: stepToSplice });
      },
      isValidIngredient(ingredient){
        console.log(ingredient)
        if(ingredient.qty == null || ingredient.qty == ''){
          return false;
        }

        if(Object.keys(ingredient).length === 0 ){
          return false;
        }

        return true;
      },
      removeIngredient(index){
        var itemToSplice = this.editedRecipe.ingredients.find( (item, ind) => ind === index )
        this.editedRecipe.ingredients.splice(index,1);

        this.addChange('remove', 'ingredient', itemToSplice)
        // this.changes.push({action: 'remove', type: 'ingredient', data: itemToSplice })
      },
      saveEditingIngredient(){
        // Replace the item at the index
        var ingredientToSwap = this.editedRecipe.ingredients.find( (element, ind) => ind === this.editedIngredient.index);
        console.log(ingredientToSwap)

        var ingredientBeingEdited = this.editedIngredient;
        console.log(ingredientBeingEdited)

        if(!this.isValidIngredient(ingredientBeingEdited)){
          this.$emit('showAlert', {text: 'There\'s something wrong with the ingredient being edited.', type: 'error', top: true});
          return;
        }

        this.addChange('update', 'ingredient', {original: ingredientToSwap, new: ingredientBeingEdited});
        // this.addChange('remove', 'ingredient', ingredientToSwap);

        // Swap the ingredients
        this.editedRecipe.ingredients.splice(ingredientToSwap.index, 1, ingredientBeingEdited);
        // Done since we've clicked save
        this.editingIngredient = false;

      },
      saveNewIngredient(){

        if(this.newIngredientQty == null || this.newIngredientQty == ''){
          this.$emit('showAlert', {text: 'You\'re missing the qty', type: 'warning', top: true});
          return;
        }

        if(this.editedRecipeImage !== null){
					var storageRef = Storage.ref();
					var imagesCollectionsRef = storageRef.child(`images/${this.user.data.id}/recipes/${this.uploadedImageFileName}.${this.editedRecipeImage``.name.split('.').pop()}`);

					var uploadTask = imagesCollectionsRef.put(this.uploadedImage);
					uploadTask.on('state_changed', 
						(snapshot) => {
							var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
							if(progress === 100){
								this.uploadingImage = false;
								this.$emit('showAlert', {text: 'Sucessfully uploaded your image!', type: 'success'});
							}
						}, 
						(error) => {
							this.$emit('showAlert', {text: 'There was an error uploading your image. Check the console.', type: 'error'});
							console.log(error);
						}, 
						() => {
							uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
								this.uploadedImageDownloadURL = downloadURL;
								this.$emit('showAlert', {text: 'The file was uploaded successfull.', type: 'success'});
							});
						}
					);
        }

        var newIngredientToAdd = {
          measurement: this.newIngredientMsrmnt,
          qty: this.newIngredientQty,
          type: this.newIngredient.type
        }
        this.editedRecipe.ingredients.push(newIngredientToAdd);

        // Add to list of changes
        this.addChange('add', 'ingredient', newIngredientToAdd)
        
        // this.changes.push({action: 'add', type: 'ingredient', data: newIngredientToAdd})
        // Reset newStuffs
        this.newIngredient = {};
        this.newIngredientQty = null;
        this.newIngredientMsrmnt = 'mL';
        
        this.$emit('showAlert', {text: 'Added new ingredient!', type: 'success', top: true})
        this.addNewIngredient = false;
      },
      updateRecipePublicity(value){
        // console.log(value)
        this.addChange('update', 'publicity', {new: value})
      },
      cancelEdit(){
        // Current iteration just overwrites the editedRecipe
        // Would like to iterate over changes and "reverse them"
        if(this.originalRoutedRecipe.name != this.editedRecipe.name){
          this.editedRecipe.name = this.originalRoutedRecipe.name;
        }
        var change;
        while(change = this.changes.pop()){
          switch(change.action){
            case 'add':
              // Need to remove the change
              switch(change.type){
                case 'step':
                  this.editedRecipe.steps.splice(change.data.index, 1);
                  break;
                case 'ingredient':
                  // Find and remove ingredient
                  var foundIngredientToRemoveIndex = this.editedRecipe.ingredients.findIndex( ingred => ingred.type.name == change.data.type.name && ingred.type.type == change.data.type.type && ingred.qty == change.data.qty && ingred.measurement == change.data.measurement);
                  this.editedRecipe.ingredients.splice(foundIngredientToRemoveIndex, 1)
                  break;
                case 'publicity':
                  this.editedRecipe.private = false;
                  break;
                case 'thumbnail':
                  this.editedRecipeImage = null;
                  this.editedRecipeImageUrl = '';
                default:
                  break;
              }
              break;
            case 'remove':
              // Add the change back in
              switch(change.type){
                case 'step':
                  this.editedRecipe.steps.splice(change.data.index, 0, change.data.step)
                  break;
                case 'ingredient':
                  this.editedRecipe.ingredients.push(change.data)
                  break;
                case 'publicity':
                  this.editedRecipe.private = true;
                  break;
                default:
                  break;
              }
              break;
            case 'update':
              switch(change.type){
                case 'publicity':
                  this.editedRecipe.private = !change.data.new;
                  this.editedRecipe.public = change.data.new;
                  break;
                case 'step':
                  var originalStep = change.data.orignal;
                  var foundStepIndex = this.editedRecipe.steps.findIndex( (item) => item == change.data.new)
                  console.log(originalStep);
                  console.log(foundStepIndex);
                  this.editedRecipe.steps.splice(foundStepIndex, 1, originalStep)
                  break;
                case 'thumbnail':
                  this.editedRecipeImage = null;
                  this.editedRecipeImageUrl = '';
                default:
                  break;
              }
              break;
            default:
              break;
          }
        }

        this.isEditing = false;
      },
      saveEdit(){
        //Add the items to be changed to an object, and call the update method
        DB.collection('recipes')
          .doc(this.editedRecipe.id)
          .set(this.editedRecipe, {merge: true});

        this.changes = [];
        this.isEditing = false;

        this.$emit('showAlert', {text: 'Your edits have been saved!', type: 'success', top: true});

      },
      addChange(action, type, data){
        console.log(action)
        console.log(type)
        console.log(data)

        // If trying to add an action, see if the reverse already exists
        if(type === 'step'){
          switch(action){
            case 'add':
              var removeFoundChange = this.changes.filter( item => item.action == 'remove' && item.data.step == data.step);

              if(removeFoundChange.length <= 0){
                this.changes.push({action: action, type: type, data: data})
              } else {
                // Remove the inverse action
                var foundIndex = this.changes.findIndex( item => item.action == 'remove' && item.data.step == data.step);
                this.changes.splice(foundIndex, 1)
              }

              break;
            case 'remove':
              var addFoundChange = this.changes.filter( item => item.action == 'add' && item.data.step == data.step);

              if(addFoundChange.length <= 0){
                this.changes.push({action: action, type: type, data: data})
              } else {
                // Found the add, need to delete it since it's "normalizing"
                var foundIndex = this.changes.findIndex( item => item.action == 'add' && item.data == data)
                this.changes.splice(foundIndex, 1);
              }

              break;
            case 'update':
              var foundUpdate = this.changes.filter( item => item.data.original == data.original && item.data.new == data.new)

              if(foundUpdate.length <= 0){
                this.changes.push({action: action, type: type, data: data});
              }
              break;
            default:
              break;
          }
        }

        if(type === 'ingredient'){
          switch(action){
            case 'add':
              var foundRemovedChanges = this.changes.filter( item => item.action == 'remove' && item.data.type.name == data.type.name && item.data.type.type == data.type.type && item.data.qty == data.qty && item.data.measurement == data.measurement)

              console.log(foundRemovedChanges);

              if(foundRemovedChanges.length <= 0){
                this.changes.push({action: action, type: type, data: data})
              } else {
                  var foundIndex = this.changes.findIndex( item => item.action == 'remove' && item.data.type.name == data.type.name && item.data.type.type == data.type.type && item.data.qty == data.qty && item.data.measurement == data.measurement)
                  this.changes.splice(foundIndex, 1);
              }
              break;
            case 'remove':
              var foundAddedChanges = this.changes.filter( item => item.action == 'add' && item.data.type.name == data.type.name && item.data.type.type == data.type.type && item.data.qty == data.qty && item.data.measurement == data.measurement)
              if(foundAddedChanges.length <= 0){
                this.changes.push({action: action, type: type, data: data})
              } else {
                var foundIndex = this.changes.findIndex( item => item.action == 'add' && item.data.type.name == data.type.name && item.data.type.type == data.type.type && item.data.qty == data.qty && item.data.measurement == data.measurement)
                this.changes.splice(foundIndex, 1);
              }
              break;
            case 'update':
              var foundUpdate = this.changes.filter(item => this.ingredientsAreTheSame(item.data.orignal, data.original) && this.ingredientsAreTheSame(item.data.new, data.new))

              if(foundUpdate.length <= 0){
                this.changes.push({action: action, type: type, data: data});
              }
              break;
            default:
              break;
          }
        }

        if(type === 'publicity'){
          switch(action){
            case 'add':
              var foundChange = this.changes.filter( item => item.action == 'remove')
              var foundChangeIndex = this.changes.findIndex( item => item.action == 'remove')

              if(foundChange <= 0){
                // If there's not a found item, add the change
                this.changes.push({action: action, type: type, data: data})

              } else {
                this.changes.splice(foundChangeIndex, 1);
              }
              break;
            case 'remove':
              var foundChange = this.changes.filter( item => item.action == 'add')
              var foundChangeIndex = this.changes.findIndex( item => item.action == 'add')

              if(foundChange <= 0){
                // If there's not a found item, add the change
                this.changes.push({action: action, type: type, data: data})

              } else {
                this.changes.splice(foundChangeIndex, 1);
              }
              break;
            case 'update':
              var foundChange = this.changes.filter( item => item.data.new === !data.new );
              var foundChangeIndex = this.changes.findIndex( item => item.data.new == !data.new );

              if(foundChange <= 0){
                this.changes.push({action: action, type: type, data: data});
              } else {
                this.changes.splice(foundChangeIndex, 1);
              }
              break;
            default:
              break;
          }
        }

        if(type === 'thumbnail'){
          switch(action){
            case 'add':
              var removeFoundChange = this.changes.filter( item => item.action == 'remove' && item.data.thumbnail == data.thumbnail);

              if(removeFoundChange.length <= 0){
                this.changes.push({action: action, type: type, data: data})
              } else {
                // Remove the inverse action
                var foundIndex = this.changes.findIndex( item => item.action == 'remove' && item.data.thumbnail == data.thumbnail);
                this.changes.splice(foundIndex, 1)
              }
              break;
            case 'remove':
              var addFoundChange = this.changes.filter( item => item.action == 'add' && item.data.thumbnail == data.thumbnail);

              if(addFoundChange.length <= 0){
                this.changes.push({action: action, type: type, data: data})
              } else {
                // Found the add, need to delete it since it's "normalizing"
                var foundIndex = this.changes.findIndex( item => item.action == 'add' && item.data == data)
                this.changes.splice(foundIndex, 1);
              }

              break;
            case 'update':
              var foundUpdate = this.changes.filter( item => item.data.original == data.original && item.data.new == data.new)

              if(foundUpdate.length <= 0){
                this.changes.push({action: action, type: type, data: data});
              }
              break;
          }
        }
      },
      getIngredientColor(ingredientType = ''){
        let type = ingredientType.type != undefined ? typeof ingredientType.type == 'object' ? ingredientType.type.type.toLowerCase() : '' : '';

        switch(type){
          case 'whiskey':
            return 'yellow darken-3';
            break;
          case 'whisky':
            return 'yellow darken-3';
            break;
          case 'brandy':
            return 'red';
            break;
          case 'vodka':
            return 'brown';
            break;
          case 'rum':
            return 'deep-orange';
            break;
          case 'gin':
            return 'green';
            break;
          case 'tequila':
            return 'light-green';
            break;
          case 'liqueur':
            return 'teal';
            break;
          default:
            return 'blue-grey';
        }
      },
      ingredientsAreTheSame(first, second){
        var areTheSame = true;

        if(first.measurement != second.measurement || first.qty != second.qty){
          areTheSame = areTheSame && false;
        }

        if(Object.entries(first.type).length != Object.entries(second.type).length){
          areTheSame = areTheSame && false;
        } else {
          // Iterate through all the keys, and see if they are the same
          areTheSame = Object.keys(first.type).every( key => {
            return second.type.hasOwnProperty(key) && first.type[key] === second.type[key]
          }) && areTheSame;
        }

        console.log(areTheSame);
        return areTheSame;
      }
    }
  }
</script>